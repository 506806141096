<template>
  <div>
    <b-overlay :show="visible"
               variant="white"
               :opacity="opacity"
               :rounded="rounded"
               blur="1rem">
      <slot></slot>
      <template #overlay>
        <div v-if="content_wait" class="text-center">
          <div>
            <img src="@/assets/images/wired-flat-45-clock-time.gif" alt="wired-flat-45-clock-time" width="40%">
          </div>
          <div class="ml-3 mt-2 font-size-16 text-bold text-primary">{{$t('common.running_subscription')}}</div>
          <div class="ml-3 mt-2 text-gray">{{$t('common.wait_time')}}</div>
        </div>
        <div v-else>
          <div class="text-center text-primary d-flex">
            <b-spinner/>
            <div class="ml-3 mt-2" v-if="is_text">{{$t('loading')}}</div>
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
export default {
  props: {
    rounded: {
      type: Boolean,
      default: true
    },
    visible: {
      required: false
    },
    content_wait: {
      type: Boolean,
      required: false
    },
    is_text: {
      required: false,
      default: true
    },
    opacity: {
      required: false,
      default: 1
    }
  }
}
</script>
<style>
.rounded {
  border-radius: 20px !important;
}
</style>