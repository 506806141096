<template>
  <div>
    <topic-component :topic="$t('account.topic')" />
    <b-card class="mb-2">
      <b-row>
        <b-col>
          <b-tabs justified>
            <!-- Account -->
            <b-tab active>
              <template #title>
                {{ $t('account.list.account') }} <i class="ml-1 fas fa-user"></i>
              </template>
              <b-card-text>
                <overlay-page :visible="is_loading" rounded :opacity="0.9">
                  <div v-if="customer_profile.identity_verify_status">
                    <b-card class="mt-3 box-card-shadow" v-if="customer_profile.identity_verify_status === 'verified'">
                      <div class="ml-3 mr-3">
                        <b-row align-h="between">
                          <b-col sm="12" md="12" lg="6" xl="6" class="text-left">
                            <label class="ml-2 mr-3 text-primary">{{ $t('account.verify_account.account_type') }} :
                              {{ verifyInfo?.customer_type === 'personal' ? $t('account.verify_account.personal') : $t('account.verify_account.juristic') }}</label>
                            <b-button class="btn-verify-success-account" @click="getVerifyInfoAccountPopup">
                              <div class="d-flex">
                                <img src="@/assets/images/icon/new_releases.svg" alt="ar-on-you">
                                <div class="ml-2 mt-1">{{ $t('account.verify_account.verified') }}</div>
                              </div>
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </b-card>
                    <div v-else-if="customer_profile.identity_verify_status === 'waiting-approve'">
                      <b-card class="mt-3 box-card-shadow">
                        <div class="ml-3 mr-3 d-flex">
                          <label class="ml-2 mr-3 text-primary mt-2">{{ $t('account.verify_account.verify_status') }}
                            :</label>
                          <div class="btn-verify-waiting-account">
                            <div class="ml-2">{{ $t('account.verify_account.checking') }}</div>
                          </div>
                          <label class="ml-3 mr-3 mt-2">{{ $t('account.verify_account.remark_checking') }}</label>
                        </div>
                      </b-card>
                      <div id="form-waiting-process" class="verify-waiting-box">
                        <form-wizard
                            ref="props"
                            hide-buttons
                            :title="null"
                            :subtitle="null"
                            :start-index="1"
                            step-size="xs">
                          <template v-slot:step="props">
                            <WizardStep
                                :tab="props.tab"
                                :transition="props.transition"
                                :index="props.index"
                            >
                              <small v-if="props.index === 0" style="font-size: 12px; margin: auto 2px">
                                <i class="fas fa-check text-white mt-1"/>
                              </small>
                            </WizardStep>
                          </template>
                          <tab-content :title="$t('account.verify_account.submit_identity_verify')"/>
                          <tab-content :title="$t('account.verify_account.remark_checking')"/>
                          <tab-content :title="$t('account.verify_account.verify_results')"/>
                        </form-wizard>
                      </div>
                    </div>
                    <b-card class="mt-3 box-card-shadow"
                            v-else-if="customer_profile.identity_verify_status === 'rejected'">
                      <div class="ml-3 mr-3">
                        <b-card class="mt-3 box-card-shadow">
                          <div class="ml-3 mr-3 d-flex">
                            <label class="ml-2 mr-3 text-primary mt-2">{{ $t('account.verify_account.verify_status') }}
                              :</label>
                            <div class="btn-verify-failed-account">
                              <div class="ml-2">{{ $t('account.verify_account.verify_failed') }}</div>
                            </div>
                            <div class="ml-3">
                              <div>{{ $t('account.verify_account.please_edit_info') }}
                                <a href="javascript:void (0)" class="text-bold text-primary ml-2"
                                   style="text-decoration: underline;font-size: 16px"
                                   @click="verifyAccount">{{ $t('account.verify_account.verify_again') }} </a>
                              </div>
                              <div class="text-danger d-flex">{{ $t('account.verify_account.remark') }} :
                                <div class="ml-1">{{ reject_remark }}</div>
                              </div>
                            </div>
                          </div>
                        </b-card>
                        <div id="form-reject-process" class="verify-waiting-box">
                          <form-wizard
                              ref="props-reject"
                              hide-buttons
                              :title="null"
                              :subtitle="null"
                              :start-index="-0"
                              step-size="xs">
                            <template v-slot:step="props">
                              <WizardStep
                                  :tab="props.tab"
                                  :transition="props.transition"
                                  :index="props.index"
                              >
                                <small v-if="props.index === 0" style="font-size: 12px; margin: auto 2px">
                                  <i class="fas fa-check text-white mt-1"/>
                                </small>
                              </WizardStep>
                            </template>
                            <tab-content :title="$t('account.verify_account.submit_identity_verify')"/>
                            <tab-content :title="$t('account.verify_account.remark_checking')"/>
                            <tab-content :title="$t('account.verify_account.verify_results')"/>
                          </form-wizard>
                        </div>
                      </div>
                    </b-card>
                    <b-card class="mt-3 box-card-shadow" v-else>
                      <div class="ml-3 mr-3">
                        <b-row align-h="between">
                          <b-col sm="12" md="12" lg="6" xl="6" class="text-left">
                            <img src="@/assets/images/icon/release_alert.svg" alt="release-icon">
                            <label
                                class="ml-2 text-primary">{{ $t('account.verify_account.please_verify_your_identity') }}
                              {{ $t('account.verify_account.max_benefits') }}</label>
                          </b-col>
                          <b-col sm="12" md="12" lg="6" xl="6" class="text-right">
                            <b-button class="btn-verify-account" @click="verifyAccount">
                              <div class="d-flex">
                                <img src="@/assets/images/icon/ar_on_you.svg" alt="ar-on-you">
                                <div class="ml-2">{{ $t('account.verify_account.verify') }}</div>
                              </div>
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </b-card>
                  </div>
                  <b-card class="mt-3 mb-3 box-card-shadow">
                    <b-row class="m-3">
                      <b-col cols="4">
                        <upload-img :imgUrl="account.image" :label="$t('account.information.imageProfile')"
                                    @overSize="onOverSize" @input="onUploadProfile"/>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col cols="12">
                            <input-component v-model="$v.account.email.$model" required
                                             :state="validateState($v.account.email)"
                                             :disabled-type="account.email !== null"
                                             :label-input="$t('account.from.email')"/>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <input-component v-model="$v.account.first_name.$model" required max="50"
                                             :state="validateState($v.account.first_name)"
                                             @keypress="checkIsOnlyTHEnglish"
                                             :onlyTHEnglish="true"
                                             :label-input="$t('account.from.fullName')"/>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <input-component v-model="$v.account.last_name.$model" required max="50"
                                             :state="validateState($v.account.last_name)"
                                             @keypress="checkIsOnlyTHEnglish"
                                             :onlyTHEnglish="true"
                                             :label-input="$t('account.from.lastName')"/>

                          </b-col>
                          <b-col sm="12" lg="6">
                            <input-component v-model="account.phone" @keypress="chkNumber" max="11" disabled-type
                                             :label-input="$t('account.from.phone')"
                                             :state="validateState($v.account.phone) && telState"
                                             :require-label="validateState($v.account.phone) !== null && !$v.account.phone.required ? $t('common.requiredField') : !telState ? telInvalid : ''"/>
                          </b-col>
                          <b-col sm="12" lg="6">
                            <select-component :label-select="$t('account.from.language')" v-model="account.locale"
                                              :options="localeOption" text-field="text"/>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div class="text-right">
                      <b-button variant="primary" :disabled="$v.account.$invalid" @click="updateAccount()"><i
                          class="fas fa-save"></i> {{ $t('button.save') }}
                      </b-button>
                    </div>
                  </b-card>
                </overlay-page>
              </b-card-text>
            </b-tab>
            <!-- Notification -->
            <b-tab>
              <template #title>
                {{ $t("subscriptions.setUpNotifications") }} <i class="ml-1 fas fa-bell"></i>
              </template>
              <b-card-text>
                <div
                    v-if="!showingNotifyPlanExpire && !showingNotifySettingEmail && !showingNotifySettingSMS"
                    class="flex-middle p-5 mt-5 mb-5">
                  {{ $t("account.notification.setting_not_found") }}
                </div>
                <b-card v-if="showingNotifyPlanExpire"
                        class="m-4 box-card-shadow">
                  <b-row>
                    <b-col class="p-4">
                      <div class="flex-center">
                        <b-form-checkbox switch size="lg" v-model="plan_expires.is_notify">
                          <span class="text-header text-secondary">
                            {{ $t("account.notification.notificationPlanExpire") }}
                          </span>
                          <span style="color: #9F9F9F; font-weight: 400;">
                            ({{ $t("account.notification.warning_can_not_change") }})
                          </span>
                        </b-form-checkbox>
                      </div>
                      <b-collapse v-model="plan_expires.is_notify">
                        <b-col class="box-card-shadow p-3 mt-3">
                          <b-row>
                            <b-col
                                v-if="notification_setting?.email?.is_active && notification_setting?.email?.customer_notification?.notify_plan_expire"
                                cols="6">
                              <b-form-checkbox switch v-model="notification_switch_list.plan_expires_email">
                                <span class="text-secondary">
                                  {{ $t("account.notification.getEmailNotification") }}
                                </span>
                              </b-form-checkbox>
                            </b-col>
                            <b-col
                                v-if="notification_setting?.sms?.is_active && notification_setting?.sms?.marketing?.notify_plan_expire"
                                cols="6">
                              <b-form-checkbox switch v-model="notification_switch_list.plan_expires_sms">
                                <span class="text-secondary">
                                  {{ $t("account.notification.getSMSlNotification") }}
                                </span>
                              </b-form-checkbox>
                            </b-col>
                          </b-row>
                          <b-row align-v="start" class="mt-3">
                            <b-col cols="6">
                              <div class="mt-2">
                                {{ $t("subscriptions.notificationBeforeExpiration") }}
                              </div>
                            </b-col>
                            <b-col cols="3">
                              <select-component :options="exp_options" v-model="plan_expires.expires_in"/>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card v-if="showingNotifySettingEmail" class="m-4 box-card-shadow">
                  <b-row>
                    <b-col class="p-4">
                      <div class="flex-center text-header text-secondary">
                        <span class="pr-1">{{ $t("account.notification.getEmailNotification") }}</span>
                        <span style="color: #9F9F9F; font-weight: 400;">
                          ({{ $t("account.notification.systemNotiToYourEmail") }})
                        </span>
                      </div>
                      <b-col class="box-card-shadow p-3 mt-3">
                        <b-row v-if="notification_setting?.email?.customer_notification?.login">
                          <b-col cols="12">
                            <b-form-checkbox switch v-model="notification_switch_list.email_login">
                              <span class="text-secondary">
                                {{ $t("account.notification.notificationList.login") }}
                              </span>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-if="notification_setting?.email?.customer_notification?.approve_sender_name">
                          <b-col cols="12">
                            <b-form-checkbox switch v-model="notification_switch_list.email_approve_sender_name">
                              <span class="text-secondary">
                                {{ $t("account.notification.notificationList.approvedSenderName") }}
                              </span>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-if="notification_setting?.email?.customer_notification?.notify_out_of_credit">
                          <b-col cols="12">
                            <b-form-checkbox switch v-model="notification_switch_list.email_credit_below_1000">
                              <span class="text-secondary">
                                {{ $t("account.notification.notificationList.creditBelow1000") }}
                              </span>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-if="notification_setting?.email?.customer_notification?.subscription">
                          <b-col cols="12">
                            <b-form-checkbox switch v-model="notification_switch_list.email_subscription">
                              <span class="text-secondary">
                                {{ $t("account.notification.notificationList.subscription") }}
                              </span>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-card>
                <b-card
                    v-if="showingNotifySettingSMS"
                    class="m-4 box-card-shadow">
                  <b-row>
                    <b-col class="p-4">
                      <div class="flex-center">
                        <div class="flex-center text-header text-secondary">
                          <span class="pr-1">
                            {{ $t("account.notification.smsNotification") }}
                          </span>
                          <span style="color: #9F9F9F; font-weight: 400;">
                            ({{ $t("account.notification.systemNotiToYourSMS") }})
                          </span>
                        </div>
                      </div>
                      <b-col class="box-card-shadow p-3 mt-3">
                        <b-row>
                          <b-col cols="12">
                            <b-form-checkbox switch v-model="notification_switch_list.sms_credit_below_1000">
                              <span class="text-secondary">
                                {{ $t("account.notification.notificationList.creditBelow1000") }}
                              </span>
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-card>
              </b-card-text>
              <div class="flex-center"
                   v-if="!(notification_setting?.email?.is_active || notification_setting?.sms?.is_active) || (notification_setting?.email?.customer_notification?.notify_plan_expire || notification_setting?.sms?.marketing?.notify_plan_expire)">
                <b-button variant="primary" @click="saveNotification()" class="ml-auto"><i class="fas fa-save"></i> {{
                    $t('button.save')
                  }}
                </b-button>
              </div>
            </b-tab>
            <!-- Security -->
            <b-tab>
              <template #title>
                {{ $t('account.list.security') }} <i class="ml-1 fas fa-lock"></i>
              </template>
              <b-card-text>
                <b-card class="m-4">
                  <b-row>
                    <b-col sm="12" lg="6">
                      <input-component v-model="$v.security.old_password.$model"
                                       :state="validateState($v.security.old_password)" required icon="password"
                                       type="password"
                                       :label-input="$t('account.security.currentPassword')"/>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <input-component v-model="$v.security.new_password.$model"
                                       :state="validateState($v.security.new_password)" required icon="password"
                                       type="password"
                                       :label-input="$t('account.security.newPassword')"/>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <input-component v-model="$v.security.confirm_password.$model"
                                       :state="validateState($v.security.confirm_password)" required icon="password"
                                       type="password"
                                       :label-input="$t('account.security.confirmPassword')"/>
                    </b-col>
                  </b-row>
                  <div class="text-right mt-4">
                    <b-button variant="primary" :disabled="$v.security.$invalid" @click="changePassword()"><i
                        class="fas fa-save"></i> {{ $t('button.save') }}
                    </b-button>
                  </div>
                </b-card>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <VerifyModal @update="updateVerifyInfoSuccess" :customer-profile="customer_profile" :setting-brand="notification_setting"/>
      <verify-info v-if="verifyInfo" :data="verifyInfo" :user-info="customer_profile" @update-file-success="verifyInfoAccount"/>
    </div>
  </div>
</template>
<script>
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";
import TopicComponent from "@/components/common/topicComponent";
import authApi from "@/repository/authApi";
import {required, email, sameAs, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import settingApi from "@/repository/settingApi";
import functionsCommon from "@/common/functions";
import profileApi from "@/repository/profileApi";
import alert from "@/common/alert";
import countries from '@/common/countries.json'
import uploadImg from "@/components/common/uploadImg";
import functionCommon from "@/common/functions";
import SwitchComponent from "@/components/common/switchComponent";
import {FormWizard, TabContent, WizardStep} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VerifyModal from "@/views/account/verify/index.vue";
import VerifyInfo from "@/views/account/verify/verifyInfo.vue";
import masterData from "@/common/masterData.json";
import OverlayPage from "@/components/common/overlayPage.vue";

export default {
  name: "account",
  components: {
    OverlayPage,
    VerifyInfo,
    TopicComponent,
    SelectComponent,
    InputComponent,
    uploadImg,
    SwitchComponent,
    FormWizard,
    TabContent,
    WizardStep,
    VerifyModal
  },
  mixins: [validationMixin],
  data() {
    return {
      account: {
        email: '',
        first_name: '',
        last_name: '',
        locale: '',
        phone: null,
        image: ''
      },
      security: {
        old_password: '',
        new_password: '',
        confirm_password: ''
      },
      information: {
        company: "",
        tax_number: "",
        website: "",
        address: "",
        sub_district: "",
        district: "",
        province: "",
        zipcode: "",
        country: null,
      },
      localeOption: [],
      customerId: null,
      countyOption: countries,
      exp_options: [
        {
          value: 1,
          en: "1 Day",
          th: "1 วัน",
          cn: "1 天",
          vn: "1 Ngày",
          my: "1 Hari",
          id: "1 Hari",
        },
        {
          value: 7,
          en: "7 Day",
          th: "7 วัน",
          cn: "7 天",
          vn: "7 Ngày",
          my: "7 Hari",
          id: "7 Hari",
        },
        {
          value: 15,
          en: "15 Day",
          th: "15 วัน",
          cn: "15 天",
          vn: "15 Ngày",
          my: "15 Hari",
          id: "15 Hari",
        },
        {
          value: 30,
          en: "30 Day",
          th: "30 วัน",
          cn: "30 天",
          vn: "30 Ngày",
          my: "30 Hari",
          id: "30 Hari",
        }
      ],
      plan_expires: {
        is_notify: false,
        expires_in: 2,
        is_notified: false,
        channel: ["sms"],
        notify_at: "",
      },
      notification: {
        approve_sender_name: [],
        login: [],
        credit_below_1000: [],
        subscription: [],
      },
      notification_switch_list: {
        plan_expires_email: false,
        plan_expires_sms: false,
        sms_credit_below_1000: false,
        email_approve_sender_name: false,
        email_login: false,
        email_credit_below_1000: false,
        email_subscription: false,
      },
      notification_setting: {},
      customer_profile: {},
      verifyInfo: {},
      reject_remark: "",
      verifyRemarkList: masterData.verifyRemarkList,
      is_loading: false
    }
  },
  validations: {
    account: {
      phone: {required},
      email: {required, email},
      first_name: {required},
      last_name: {required}
    },
    security: {
      old_password: {required},
      new_password: {
        required,
        minLength: minLength(6)
      },
      confirm_password: {required, sameAsPassword: sameAs('new_password')}
    },
  },
  mounted() {
    this.getUserInfo()
    this.getLanguageOption()
    this.getBrandSettingOtpType()
  },
  computed: {
    telState() {
      return functionCommon.telState(this.account.phone)
    },
    telInvalid() {
      const data = functionCommon.telInvalid(this.account.phone)
      return this.$t(data)
    },
    showingNotifySettingSMS() {
      return (this.notification_setting?.sms?.is_active) && this.notification_setting?.sms?.marketing?.notify_out_of_credit;
    },
    showingNotifySettingEmail() {
      return (this.notification_setting?.email?.is_active) &&
          (this.notification_setting?.email?.customer_notification?.login ||
              this.notification_setting?.email?.customer_notification?.approve_sender_name ||
              this.notification_setting?.email?.customer_notification?.notify_out_of_credit ||
              this.notification_setting?.email?.customer_notification?.subscription);
    },
    showingNotifyPlanExpire() {
      return (this.notification_setting?.email?.is_active || this.notification_setting?.sms?.is_active) && (this.notification_setting?.email?.customer_notification?.notify_plan_expire || this.notification_setting?.sms?.marketing?.notify_plan_expire)
    }
  },
  methods: {
    validateState(validation) {
      const {$dirty, $error} = validation;
      return $dirty ? !$error : null;
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    getUserInfo() {
      this.is_loading = true
      authApi.getCustomerProfile().then(resp => {
        this.customer_profile = resp
        this.customerId = resp?._id
        this.account = {
          ...this.account,
          email: resp?.email,
          first_name: resp?.first_name,
          last_name: resp?.last_name,
          locale: resp?.locale,
          phone: resp?.phone,
          image: resp?.image
        }
        this.plan_expires = {
          ...this.plan_expires,
          is_notify: resp?.plan_expires?.is_notify,
          expires_in: resp?.plan_expires?.expires_in,
          is_notified: resp?.plan_expires?.is_notified,
          channel: resp?.plan_expires?.channel,
          notify_at: resp?.plan_expires?.notify_at,
        }
        this.notification = {
          ...this.notification,
          approve_sender_name: resp?.notification?.approve_sender_name,
          login: resp?.notification?.login,
          credit_below_1000: resp?.notification?.credit_below_1000,
          subscription: resp?.notification?.subscription,
        }
        this.setDataToSwitchList()
        this.verifyInfoAccount()
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading = false
      })
    },
    updateAccount() {
      if (!this.account.image) {
        this.account.image = null
      }
      const data = {
        first_name: this.account.first_name,
        last_name: this.account.last_name,
        locale: this.account.locale,
        image: this.account.image
      }
      profileApi.updateAccountInfo(data, this.customerId).then(resp => {
        if (resp) {
          alert.updateSuccess(this)
          this.$store.dispatch('profile/setName', resp?.customer?.first_name + " " + resp?.customer?.last_name)
          this.$store.dispatch('profile/setProfileImg', resp?.customer?.image)
          this.getUserInfo()
        } else {
          alert.updateFailed(this, resp?.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err)
      })
    },
    getLanguageOption() {
      settingApi.getLanguageList().then(resp => {
        const rawData = _.get(resp, 'data', [])
        this.localeOption = _.map(rawData, item => {
          return {
            text: item?.name,
            value: item?.code
          }
        })
      }).catch(err => {
        throw err
      })
    },
    chkNumber(e) {
      return functionsCommon.checkNumber(e)
    },
    changePassword() {
      profileApi.changePasswordCustomerProfile(this.security).then(resp => {
        if (resp) {
          alert.updateSuccess(this)
          this.getUserInfo()
        } else {
          alert.updateFailed(this, resp?.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err)
      })
    },
    onOverSize() {
      alert.updateFailed(this, 'File size cannot be over than 25MB')
    },
    onUploadProfile(value) {
      this.account.image = value
    },
    checkingChannelInArray(channel, array) {
      return array.includes(channel)
    },
    setDataToSwitchList() {
      this.notification_switch_list.plan_expires_email = this.checkingChannelInArray('email', this.plan_expires.channel)
      this.notification_switch_list.plan_expires_sms = this.checkingChannelInArray('sms', this.plan_expires.channel)

      // Setting SMS
      this.notification_switch_list.sms_credit_below_1000 = this.checkingChannelInArray('sms', this.notification.credit_below_1000)

      // Setting Email
      this.notification_switch_list.email_approve_sender_name = this.checkingChannelInArray('email', this.notification.approve_sender_name)
      this.notification_switch_list.email_login = this.checkingChannelInArray('email', this.notification.login)
      this.notification_switch_list.email_credit_below_1000 = this.checkingChannelInArray('email', this.notification.credit_below_1000)
      this.notification_switch_list.email_subscription = this.checkingChannelInArray('email', this.notification.subscription)

    },
    saveNotification() {
      const plan_expire = {
        is_notify: this.plan_expires.is_notify,
        expires_in: this.plan_expires.expires_in,
        channel: [],
      }
      if (this.notification_switch_list.plan_expires_email) {
        plan_expire.channel.push('email')
      }
      if (this.notification_switch_list.plan_expires_sms) {
        plan_expire.channel.push('sms')
      }

      const notification = {
        approve_sender_name: [],
        login: [],
        credit_below_1000: [],
        subscription: [],
      }

      const twoTypes = ['credit_below_1000',];
      const mediums = ['sms', 'email'];
      twoTypes.forEach(type => {
        mediums.forEach(medium => {
          if (this.notification_switch_list[`${medium}_${type}`]) {
            notification[type].push(medium);
          }
        });
      });

      const email = ['approve_sender_name', 'login', 'subscription'];
      email.forEach(type => {
        if (this.notification_switch_list[`email_${type}`]) {
          notification[type].push('email');
        }
      });

      const data = {
        ...notification,
        plan_expire
      }

      profileApi.updateNotification(this.customerId, data).then(resp => {
        if (resp) {
          alert.updateSuccess(this)
          this.getUserInfo()
        } else {
          alert.updateFailed(this, resp?.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err)
      })
    },
    getBrandSettingOtpType() {
      settingApi.getBrandSetting().then(resp => {
        this.notification_setting = resp?.notification
      }).catch((err) => {
        throw err
      }).finally(() => {
        this.is_loading_setting = false
      })
    },
    checkIsOnlyTHEnglish(e) {
      return functionsCommon.isOnlyTHEnglish(e)
    },
    verifyAccount() {
      this.$bvModal.show('modal-verify-account')
    },
    updateVerifyInfoSuccess(e) {
      if (e) {
        window.location.reload();
      }
    },
    verifyInfoAccount() {
      profileApi.getVerifyInfo().then(resp => {
        if (!!resp) {
          this.verifyInfo = resp
          let array = []
          _.forEach(resp.remark, item => {
            array.push(this.filterValue(this.verifyRemarkList, item))
            this.reject_remark = _.join(array, ' , ')
          })
        }
      }).catch(err => {
        throw err
      })
    },
    getVerifyInfoAccountPopup() {
      this.$bvModal.show('modal-verify-info')
    }
  },
  watch: {
    '$i18n.locale'(val) {
      this.verifyInfoAccount()
    }
  }
}
</script>

<style scoped lang="scss">
label {
  display: unset;
}

.box-account {
  padding: 30px;
  border-radius: 10px;
  /*border: 1px solid #3b82f6;*/
  background-color: #F6F7FB
}

.text-header {
  font-size: 16px;
  font-weight: 600;
}

.block-header-collapse {
  background: #FFFFFF !important;
  border: #FFFFFF !important;
  border-radius: 10px;
  padding: 1rem;
  color: #0c0d10;
  text-align: left;
}

#accordion-box::v-deep .card-body {
  border: 1px solid #E9E9E9;
  border-radius: 0 0 15px 15px;
}

.btn-verify-account {
  background: linear-gradient(90deg, #00C0CD 0%, #003191 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  padding: .6rem 3rem .6rem 3rem;
  vertical-align: middle;
}

.btn-verify-account:hover, .btn-verify-account:focus {
  background: linear-gradient(90deg, #00C0CD 0%, #003191 100%) !important;
}

.btn-verify-success-account {
  background: linear-gradient(90deg, #02A4A4 0%, #00E3BA 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  padding: .6rem 2rem .6rem 2rem;
  vertical-align: middle;
}

.btn-verify-success-account:hover, .btn-verify-success-account:focus {
  background: linear-gradient(90deg, #02A4A4 0%, #00E3BA 100%) !important;
}

.btn-verify-waiting-account {
  padding: .6rem 2rem .6rem 2rem;
  border-radius: 2rem;
  background: rgba(255, 247, 175, 1);
  color: rgba(227, 177, 0, 1);
  vertical-align: middle;
}

.btn-verify-failed-account {
  padding: .6rem 2rem .6rem 2rem;
  border-radius: 2rem;
  background: #FFDADA;
  color: #D60000;
  vertical-align: middle;
}

.verify-waiting-box {
  border: 1px solid rgba(233, 233, 233, 1);
  padding: .5rem;
  border-radius: 0px 0 10px 10px;
  margin-top: -1rem;
}

#form-waiting-process::v-deep {
  .vue-form-wizard {
    text-align: center !important;
    font-style: normal !important;
    padding-bottom: 10px !important;
  }

  .vue-form-wizard .wizard-header {
    padding: 0 !important;
  }

  .vue-form-wizard .wizard-tab-content {
    min-height: 0 !important;
    padding: 0;
  }

  .vue-form-wizard .wizard-nav-pills > li > a {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 auto !important;
    color: #0003 !important;
    position: relative !important;
    top: 3px !important;
    font-style: normal !important;
  }

  .vue-form-wizard .wizard-icon-circle.checked {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: var(--primary-color) !important;
    border: 3px solid #FFFFFF !important;
    box-shadow: 0 0 0 3px var(--primary-color) !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: #AAAAAA !important;
    border: 0 solid #fff !important;
    box-shadow: 0 0 0 3px #AAAAAA !important;
    color: #FFFFFF !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle .wizard-icon {
    font-style: normal !important;
  }

  .wizard-icon-container {
    background-color: transparent !important;
  }

  .wizard-progress-bar {
    background-color: var(--primary-color) !important;
  }

  .wizard-progress-with-circle {
    background-color: #AAAAAA !important;
    width: 65% !important;
  }

  .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
    position: relative !important;
    top: 28px !important;
    height: 4px !important;
    display: inline-block !important;
  }

  .stepTitle.active {
    color: #2F2E2E !important;
  }
  @media (max-width: 768px) {
    .vue-form-wizard .wizard-tab-content {
      min-height: 100px;
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-header {
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-icon-circle {
      width: 1.6em !important;
      height: 1.6em !important;
    }

    .vue-form-wizard .wizard-icon-circle.checked {
      width: 1.6em !important;
      height: 1.6em !important;
    }
  }


}
#form-reject-process::v-deep {
  .vue-form-wizard {
    text-align: center !important;
    font-style: normal !important;
    padding-bottom: 10px !important;
  }

  .vue-form-wizard .wizard-header {
    padding: 0 !important;
  }

  .vue-form-wizard .wizard-tab-content {
    min-height: 0 !important;
    padding: 0;
  }

  .vue-form-wizard .wizard-nav-pills > li > a {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 auto !important;
    color: #0003 !important;
    position: relative !important;
    top: 3px !important;
    font-style: normal !important;
  }

  .vue-form-wizard .wizard-icon-circle.checked {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: #AAAAAA !important;
    border: 0 solid #FFFFFF !important;
    box-shadow: 0 0 0 3px #AAAAAA !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle {
    width: 2em !important;
    height: 2em !important;
    border-radius: 50% !important;
    background-color: #AAAAAA !important;
    border: 0 solid #fff !important;
    box-shadow: 0 0 0 3px #AAAAAA !important;
    color: #FFFFFF !important;
    transition: all 0.5s ease-out !important;
  }

  .vue-form-wizard .wizard-icon-circle .wizard-icon {
    font-style: normal !important;
  }

  .wizard-icon-container {
    background-color: transparent !important;
  }

  .wizard-progress-bar {
    background-color: var(--primary-color) !important;
  }

  .wizard-progress-with-circle {
    background-color: #AAAAAA !important;
    width: 65% !important;
  }

  .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
    position: relative !important;
    top: 28px !important;
    height: 4px !important;
    display: inline-block !important;
  }

  .stepTitle.active {
    color: #0003 !important;
  }
  .wizard-progress-bar {
    background-color: #AAAAAA !important;
  }
  @media (max-width: 768px) {
    .vue-form-wizard .wizard-tab-content {
      min-height: 100px;
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-header {
      padding: 0 !important;
    }

    .vue-form-wizard .wizard-icon-circle {
      width: 1.6em !important;
      height: 1.6em !important;
    }

    .vue-form-wizard .wizard-icon-circle.checked {
      width: 1.6em !important;
      height: 1.6em !important;
    }
  }


}
</style>